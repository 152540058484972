import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public storageChanged$: Observable<Record<string, any>>;
  private storageChanged = new BehaviorSubject<Record<string, any>>(null);

  constructor() {
    this.storageChanged$ = this.storageChanged.asObservable();
  }

  public set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));

    this.storageChanged.next({ [key]: data });
  }

  public get<T = any>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  public remove(key: string): void {
    localStorage.removeItem(key);

    this.storageChanged.next({ [key]: null });
  }
}
